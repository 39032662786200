import React from "react";
import LockupEmbedded from "assets/img/adaptable-lockup.svg";

export {
    LockupEmbedded,
};

export interface LogoProps {
    className?: string;
    /** Include full "Adaptable" text in logo. False for just the rocket with no text. */
    lockup?: boolean;
    style?: React.CSSProperties;
    white?: boolean;
}

export default function Logo({ lockup, white, ...rest }: LogoProps) {
    const color = white ? "white" : "color";
    const type = lockup ? "lockup" : "logo";
    const src = `/img/${color} ${type}.svg`;
    return (
        <img src={src} alt="Adaptable logo" {...rest} />
    );
}
