
import React from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";

import Skeleton from "@mui/material/Skeleton";

import BusinessIcon from "@mui/icons-material/Business";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import PersonIcon from "@mui/icons-material/Person";

import { isPausedOrg, isPersonalOrg } from "api-support/organizations";
import { Organization } from "api-support/types";


interface OrgIconProps extends SvgIconProps {
    org: Organization | null | undefined,
}

const OrgIcon = ({ org, ...iconProps }: OrgIconProps) => {
    const { sx, style, className } = iconProps;
    if (org === null) return <Skeleton variant="rectangular" sx={sx} style={style} className={className} />;
    if (org === undefined) return <BusinessIcon {...iconProps} />;
    if (isPausedOrg(org)) {
        return (
            <DomainDisabledIcon {...iconProps} />
        );
    }
    if (isPersonalOrg(org)) return <PersonIcon {...iconProps} />;
    return <BusinessIcon {...iconProps} />;
};

export default OrgIcon;
