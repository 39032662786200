/** @jsxImportSource @emotion/react */

import { useTheme } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import {
    useEffect,
    useMemo,
} from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import BusinessIcon from "@mui/icons-material/Business";

import {
    useCurrentOrganizationId,
    useOrganization,
    useOrganizations,
} from "api-support/organizations";
import Link from "components/Link";
import { useNotifyOnce } from "components/Notifications";
import OrgIcon from "components/OrgIcon";
import { useAuth } from "utils/auth";

const OrgSelector = ({
    manageUrl,
}: {
    manageUrl: string,
}) => {
    const { currentOrgId, setCurrentOrgId } = useCurrentOrganizationId();
    const {
        org: currentOrg,
        error: currentOrgError,
    } = useOrganization({ orgId: currentOrgId });
    const { user } = useAuth();
    const {
        data: orgPage,
        error: orgsError,
    } = useOrganizations({ userId: user === null ? null : user?._id });
    useNotifyOnce(orgsError ? `Error loading organizations: ${orgsError.message}` : undefined, { variant: "error" });
    useNotifyOnce(currentOrgError ? `Error loading current organization: ${currentOrgError.message}` : undefined, { variant: "error" });

    const fetchedOrganizations = orgPage?.data;
    const organizations = useMemo(() => new Map([
        ...(fetchedOrganizations ?? []),
        ...(currentOrg ? [currentOrg] : []),
    ].map((o) => [o._id, o])),
    [currentOrg, fetchedOrganizations]);

    useEffect(() => {
        if (currentOrgId == null && user != null) setCurrentOrgId(user.personalOrgId);
    }, [currentOrgId, setCurrentOrgId, user]);

    useEffect(() => {
        // If the users cannot fetch details about the current org, revert to their personalOrgId
        if (user
            && currentOrgId
            && currentOrg !== null
            && organizations.get(currentOrgId) == null
            && currentOrgId !== user.personalOrgId
        ) {
            setCurrentOrgId(user.personalOrgId);
        }
    }, [user, currentOrgId, setCurrentOrgId, organizations, currentOrg, fetchedOrganizations]);

    const theme = useTheme();

    if (user === undefined) return null; // No user logged in, should not happen
    if (currentOrgId == null
        || (fetchedOrganizations == null && orgsError == null)
        || (currentOrg == null && currentOrgError == null)) {
        return (
            <Stack direction="row" gap={1} alignItems="center">
                <Skeleton data-testid="header-org-selector-skeleton" width={240} height={40} variant="rounded" />
            </Stack>
        );
    }

    if (currentOrgId
            && organizations.get(currentOrgId) == null
            && currentOrgId !== user.personalOrgId
    ) {
        return (
            <Stack direction="row" gap={1} alignItems="center">
                <Skeleton data-testid="header-org-selector-skeleton" width={240} height={40} variant="rounded" />
            </Stack>
        );
    }

    return (
        <Stack direction="row" gap={1} alignItems="center">
            <Box id="header-select-org-label" component="span" sx={visuallyHidden}>Select Organization</Box>
            <FormControl fullWidth size="small">
                <Select
                    css={{
                        color: theme.palette.primary.contrastText,
                        minWidth: "240px",
                        "& .MuiSelect-icon": {
                            color: theme.palette.primary.contrastText,
                        },
                        "& .MuiInputAdornment-root": {
                            color: theme.palette.primary.contrastText,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.primary.contrastText,
                            opacity: 0.3,
                            borderWidth: 1,
                            top: "-3px",
                            bottom: "3px",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.primary.contrastText,
                            opacity: 0.7,
                            borderWidth: 1,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.primary.contrastText,
                            opacity: 0.7,
                            borderWidth: 1,
                        },
                    }}
                    labelId="header-select-org-label"
                    id="header-select-org"
                    inputProps={{
                        style: {
                            borderColor: "#fff",
                        },
                    }}
                    startAdornment={(
                        <InputAdornment position="start">
                            {currentOrg == null
                                ? <BusinessIcon />
                                : <OrgIcon org={currentOrg} />}
                        </InputAdornment>
                    )}
                    value={currentOrgId ?? ""}
                    onChange={(e) => {
                        const { value } = e.target;
                        if (value == null) return;
                        setCurrentOrgId(value);
                    }}
                    renderValue={(selected) => {
                        if (selected == null) return <em>Select Organization</em>;
                        const org = organizations.get(selected);
                        if (org == null) return <em>Error: No Org Name</em>;
                        return org.name;
                    }}
                >
                    <ListSubheader>Select Organization</ListSubheader>
                    {([...organizations.entries()]).map(([_, org]) => (
                        <MenuItem key={org._id} value={org._id}>
                            <ListItemText>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        lineHeight: "20px",
                                        textTransform: "none",
                                    }}
                                    variant="button"
                                >
                                    {org.name}
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                    ))}
                    {currentOrgId && organizations.get(currentOrgId) == null
                        && (
                            <MenuItem key={currentOrgId} value={currentOrgId}>
                                <ListItemText>
                                    <Typography
                                        style={{
                                            fontSize: "15px",
                                            lineHeight: "20px",
                                            textTransform: "none",
                                        }}
                                        variant="button"
                                    >
                                        Org with id &quot;
                                        {currentOrgId}
                                        &quot;
                                    </Typography>
                                </ListItemText>
                            </MenuItem>
                        )}
                    <Divider variant="middle" component="li" />
                    <MenuItem
                        component={Link}
                        href={manageUrl}
                    >
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Manage Organizations
                        </ListItemText>
                    </MenuItem>
                </Select>
            </FormControl>
        </Stack>
    );
};

export default OrgSelector;
