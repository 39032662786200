import { useNotifications } from "components/Notifications";
import { useEffect } from "react";
import { getHashError, hashRemove } from "utils";

const ErrorReporter = () => {
    const { notify } = useNotifications();

    useEffect(() => {
        const errMsg = getHashError(window.location.href);
        if (errMsg) {
            // Avoid duplicate messages from the #error in the URL
            // as other libraries (like feathers app.reauthenticate)
            // will race with us and could lead to duplicate reporting
            // of these errors.
            notify(errMsg, { preventDuplicate: true, variant: "error", persist: true });
            const url = new URL(hashRemove(window.location.href, ["error"]));

            // NOTE(mark): Using router.replace here (even with
            // `shallow: true`) on pages that use BaseLayout seems to cause
            // the notification to get lost, but only in a prod build. Seems to
            // be some sort of timing/render issue that setting
            // window.location.hash does not trigger.
            window.location.hash = url.hash;
        }
    }, [notify]);
    return null;
};

export default ErrorReporter;
