import makeStyles from "@mui/styles/makeStyles";
import React, { useState, useEffect } from "react";
import Router from "next/router";

import Close from "@mui/icons-material/Close";
import Menu from "@mui/icons-material/Menu";

import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Link from "components/Link";
import Portal from "components/Portal";
import classNames from "classnames";
import PropTypes from "prop-types";

// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle";

const useStyles = makeStyles(styles as any);

function RouterProgress() {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const set = () => setIsLoading(true);
        const unset = () => setIsLoading(false);
        Router.events.on("routeChangeStart", set);
        Router.events.on("routeChangeComplete", unset);
        Router.events.on("routeChangeError", unset);

        return () => {
            Router.events.off("routeChangeStart", set);
            Router.events.off("routeChangeComplete", unset);
            Router.events.off("routeChangeError", unset);
        };
    }, []);
    return isLoading && (
        <Portal>
            <LinearProgress
                color="secondary"
                sx={{
                    position: "absolute",
                    top: "0",
                    height: "2px",
                    width: "100%",
                    zIndex: "9999",
                }}
            />
        </Portal>
    );
}

function ElevateOnScrollAppBar({
    children,
    elevation = 0,
    scrolledElevation = 4,
    scrollTarget,
    ...rest
} : AppBarProps & {
    elevation?: number,
    scrolledElevation?: number,
    scrollTarget?: Node | Window,
}) {
    const scrolled = useScrollTrigger({
        disableHysteresis: true,
        target: scrollTarget,
        threshold: 0,
    });

    return (
        <AppBar {...rest} elevation={scrolled ? scrolledElevation : elevation}>
            {children}
        </AppBar>
    );
}

ElevateOnScrollAppBar.propTypes = {
    children: PropTypes.node,
};

type Colors = "primary" | "info" | "success" | "warning" | "danger" | "transparent" | "white" | "rose" | "dark";
export interface HeaderProps {
    absolute?: boolean,
    brand: React.ReactNode,
    brandUrl: string,
    color: Colors,
    changeColorOnScroll?: {
        color: Colors,
        height: number,
    },
    elevateOnScroll?: boolean,
    fixed?: boolean,
    leftControls?: React.ReactNode,
    links?: React.ReactNode,
    scrollTarget?: Node | Window,
}

export default function Header({
    absolute,
    brand, brandUrl,
    changeColorOnScroll,
    color: propsColor,
    elevateOnScroll,
    fixed,
    leftControls,
    links,
    scrollTarget,
}: HeaderProps) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();
    const colorScrolled = useScrollTrigger({
        disableHysteresis: true,
        target: scrollTarget,
        threshold: changeColorOnScroll ? changeColorOnScroll.height : 0,
    });
    const color = colorScrolled && changeColorOnScroll ? changeColorOnScroll.color : propsColor;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const colorClass = `header-${color}`;

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: true,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed,
        [colorClass]: true,
    });

    return (
        <ElevateOnScrollAppBar
            className={appBarClasses}
            elevation={0}
            scrolledElevation={elevateOnScroll ? 4 : 0}
            scrollTarget={scrollTarget}
            style={{ flexWrap: "wrap" }}
        >
            <RouterProgress />
            <Toolbar className={classes.container}>
                <Stack direction="row" gap={4}>
                    <Link href={brandUrl}>{brand}</Link>
                    {leftControls}
                </Stack>
                <Box sx={{ display: { xs: "none", md: "block" } }} className={classes.collapse}>{links}</Box>
                <IconButton
                    sx={{ display: { xs: "block", md: "none" } }}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    size="large"
                >
                    <Menu />
                </IconButton>
            </Toolbar>
            <Drawer
                sx={{ display: { xs: "block", md: "none" } }}
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClose={handleDrawerToggle}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    className={classes.closeButtonDrawer}
                    size="large"
                >
                    <Close />
                </IconButton>
                <div className={classes.appResponsive}>{links}</div>
            </Drawer>
        </ElevateOnScrollAppBar>
    );
}

Header.defaultProps = {
    color: "white",
    brandUrl: "/",
};

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark",
    ]),
    links: PropTypes.node,
    brand: PropTypes.node,
    brandUrl: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark",
        ]).isRequired,
    }),
    elevateOnScroll: PropTypes.bool,
    leftControls: PropTypes.node,
};
