import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";

import { siteUrls } from "utils";
import logger from "utils/logger";


export interface OopsProps {
    /**
     * Use a Backdrop overlay to display the error info.
     */
    backdrop?: boolean;

    /**
     * The error that occurred. Prefer passing an Error object because that
     * will get fully logged, including stack, additional data, etc..
     */
    error: Error | string;

    /** Log this error (level critical) to console and Rollbar (default: true) */
    log?: boolean;

    /**
     * Show this message to the user instead of the message from the Error
     * object. Useful when you want to log the original not-user-friendly
     * error without modification but want a more friendly description to
     * display.
     */
    messageOverride?: string;

    /**
     * Prefix the error message with this string (followed by a space).
     */
    messagePrefix?: string;

    /**
     * Provide additional text information to the user besides the error
     * message. Useful for describing the error in more depth or for suggesting
     * potential solutions or actions.
     */
    moreInfo?: string;
}

export type ThrowOopsOptions = Omit<OopsProps, "error" | "backdrop" | "log">;

const errorSym = Symbol.for("OopsError");

export interface OopsError extends Error {
    [errorSym]?: ThrowOopsOptions;
}

export function getOopsOptions(err: Error) {
    return (err as OopsError)[errorSym];
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.appBar - 1,
        "& > div": {
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            color: "#000",
            height: "auto",
        },
    },
    content: {
        alignItems: "center",
        color: "#545454",
        minHeight: "80vh",
        justifyContent: "center",
        lineHeight: 1.2,
        padding: "5vw 0",
        "& > div": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        "& h3": {
            marginTop: "2rem",
        },
    },
    frown: {
        fontSize: "20vh",
        paddingRight: "5vw",
        whiteSpace: "nowrap",
    },
    oops: {
        fontSize: "5rem",
        lineHeight: "7rem",
    },
    pre: {
        color: "#000",
        marginBottom: "3px",
        marginLeft: "1em",
        whiteSpace: "pre-wrap",
    },
    textBox: {
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "95vw",
        "& > div": {
            marginTop: ".5rem",
        },
    },
}));

const oopsDefaults = {
    backdrop: true,
    log: true,
};

const Oops = (props: OopsProps) => {
    const classes = useStyles();
    const { error, ...propsRest } = props;
    const errorOpts = (typeof error === "string" ? null : getOopsOptions(error)) ?? {};
    const {
        backdrop,
        log,
        messageOverride,
        messagePrefix,
        moreInfo,
    } = {
        ...oopsDefaults,
        ...errorOpts,
        ...propsRest,
    };

    let message = messagePrefix ? `${messagePrefix} ` : "";
    if (messageOverride) {
        message += messageOverride;
    } else {
        message += typeof error === "string" ? error : (error.message || error.toString());
    }

    useEffect(() => {
        if (log) {
            logger.critical("Client got Oops page", {
                error,
                message,
            });
        }
    // Only ever log this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contents = (
        <Box display="flex" mx={1} className={classes.content}>
            <Box>
                <div className={classes.frown}>:(</div>
                <Box display="flex" className={classes.textBox}>
                    <Typography component="h1" variant="body1" className={classes.oops}>Oops.</Typography>
                    <Typography component="h2" variant="h5">An unexpected error happened.</Typography>
                    <div>
                        Please
                        {" "}
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={siteUrls.support} target="_blank">report this error to us</a>
                        {" "}
                        so we can get it fixed!
                    </div>

                    <Typography component="h3" variant="h6" gutterBottom>Error Message</Typography>
                    <pre className={classes.pre}>{message}</pre>

                    {moreInfo && (
                        <>
                            <Typography component="h3" variant="h6" gutterBottom>Additional Info</Typography>
                            <div style={{ marginLeft: "1em" }}>{moreInfo}</div>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    if (!backdrop) return contents;

    return (
        <Backdrop className={classes.backdrop} open>
            {contents}
        </Backdrop>
    );
};
export default Oops;


/**
 * Throws an error to purposely cause it to be caught by <ErrorBoundary>,
 * display the Oops page with the error, and log the error for analysis.
 *
 * Optionally decorates the error with options for the Oops component before
 * throwing.
 */
export function throwOops(error: Error | string, opts?: ThrowOopsOptions): never {
    const errObj: OopsError = typeof error === "string" ? new Error(error) : error;
    if (opts) errObj[errorSym] = opts;
    throw errObj;
}
