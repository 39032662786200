import { Application } from "@feathersjs/feathers";
import React, { ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";

import feathersClient from "api-support/feathers-client";
import ErrorPage from "components/ErrorPage";
import { AnyObject, appUrls } from "utils";
import logger from "utils/logger";
import { useIsOnline } from "utils/online";
import {
    User,
    useAuth,
    AuthInfo,
    logoutAndRedirectToSignIn,
} from "./auth";
import { getPathAndHash, hashRemove } from "./url";

const reAuth = (app: Application, auth: AuthInfo) => {
    const { error } = auth;
    if (error) {
        auth.error = undefined;
        return Promise.reject(error);
    }

    return app.reAuthenticate();
};

export interface WithAuthBase {
    role: "none" | "user" | "admin";
    loading?: ReactNode;
}

export const withAuthBase = ({ loading = null, role }: WithAuthBase) => <P extends AnyObject>(
    Component: React.ComponentType<P>,
): React.FC<P> => (props) => {
    const router = useRouter();
    const auth = useAuth();
    const isOnline = useIsOnline();
    const [user, setUser] = useState<User | undefined>(auth.user);
    const [logoutInMs, setLogoutInMs] = useState<number>();
    const authUrl = appUrls.signin;

    const app = feathersClient();
    useEffect(() => {
        const userListener = (e: any) => {
            setUser((e as any as { detail: { user: User } }).detail.user);
        };
        auth.addEventListener("user", userListener);
        return () => auth.removeEventListener("user", userListener);
    }, [auth]);

    useEffect(() => {
        if (!auth.user) {
            const origHref = window.location.href;
            reAuth(app, auth)
                .then((val) => {
                    const newHref = window.location.href;

                    auth.user = val.user;
                    const githubToken = val.authentication?.payload?.githubToken;
                    if (githubToken) auth.githubToken = githubToken;

                    const intercomHash = val.authentication?.payload?.intercomHash;
                    if (intercomHash) auth.intercomHash = intercomHash;

                    auth.state = "complete";

                    // Set the logout timer
                    const exp = val?.authentication?.payload?.exp;
                    if (typeof exp !== "number") throw new Error(`Invalid authentication JWT. No exp field.`);
                    const timeLeft = exp * 1000 - Date.now(); // exp is in sec
                    if (timeLeft <= 0) throw new Error(`Authentication JWT already expired. Please check your system clock and timezone.`);
                    setLogoutInMs(timeLeft);

                    // Feathers reAuth removes the access_token= from the end
                    // of the URL, but can leave a trailing ?, which causes
                    // problems with hash routing, so we remove it.
                    if (origHref.includes("?access_token=") && newHref.endsWith("?")) {
                        window.location.replace(newHref.slice(0, -1));
                    }
                })
                .catch((err) => {
                    auth.state = "complete";
                    setLogoutInMs(undefined);

                    const msg: string = err.message || "Unknown error";
                    let reportMsg: string | undefined = msg;
                    if (msg.startsWith("No accessToken found")) {
                        reportMsg = undefined;
                    } else if (msg === "jwt expired") {
                        reportMsg = "Authentication expired. Please sign in again.";
                    } else if (msg === "No such user") {
                        reportMsg = "User not found. Try signing up as a new user.";
                    } else {
                        auth.error = err;
                    }
                    if (role === "none") return;

                    let hash: string | undefined;
                    if (reportMsg) {
                        hash = `#error=${encodeURIComponent(reportMsg)}`;
                    }
                    const redirect = getPathAndHash(hashRemove(window.location.href, ["access_token", "error"]));
                    // eslint-disable-next-line promise/no-nesting
                    router.replace({
                        pathname: authUrl,
                        query: {
                            redirect,
                        },
                        hash,
                    // eslint-disable-next-line promise/no-nesting
                    }).catch((e) => logger.error(`Error replacing router URL:`, {}, e));
                });
        } else {
            const removed = hashRemove(window.location.href, ["access_token"]);
            const [, newhash] = removed.split("#");
            if (window.location.hash !== newhash) {
                window.location.hash = newhash ?? "";
            }
        }
    }, [app, auth, auth.user, authUrl, router]);

    useEffect(() => {
        if (!logoutInMs) return undefined;
        const timer = setTimeout(() => {
            setLogoutInMs(undefined);
            logoutAndRedirectToSignIn(auth);
        }, logoutInMs);
        return () => clearTimeout(timer);
    }, [auth, logoutInMs]);

    // Only keep showing the loading state if we're online. If we can't get
    // the user info (yet) because we're offline, fall through and just show
    // the page anyway, which will show the Offline backdrop in the AppLayout.
    if (role !== "none" && isOnline && !user) return loading;

    if (role === "admin" && !auth.isAdmin) return <ErrorPage statusCode={403} title="Forbidden" />;
    return <Component {...props} />;
};

const withAuthRequired = withAuthBase({ role: "user" });
export default withAuthRequired;
