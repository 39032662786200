import React, { ReactNode, ErrorInfo, ComponentType } from "react";

import Oops, { getOopsOptions } from "components/Oops";
import logger from "utils/logger";


interface FallbackProps {
    backdrop: boolean;
    error: Error;
    log: boolean;
}

interface ErrorBoundaryProps {
    children: ReactNode | ReactNode[];
    Fallback: ComponentType<FallbackProps>;
}

interface ErrorBoundaryState {
    error?: Error;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        // NOTE: Lint rule doesn't understand how types work with defaultProps
        // in a class Component.
        // eslint-disable-next-line react/default-props-match-prop-types
        Fallback: Oops,
    };

    static getDerivedStateFromError(error: any) {
        if (error == null || typeof error !== "object" || !error.message) {
            error = new Error(`Invalid error object thrown: ${error}`);
        }
        return { error };
    }

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const oopsOpts = getOopsOptions(error);
        logger.critical("Client got Oops page (ErrorBoundary)", {
            errorInfo,
            oopsOpts,
        }, error);
    }

    render() {
        const { children, Fallback } = this.props;
        const { error } = this.state;

        if (error) return <Fallback backdrop={false} error={error} log={false} />;

        return children;
    }
}
export default ErrorBoundary;
