import React, { useEffect } from "react";

import NextErrorPage, { ErrorProps as NextErrorProps } from "next/error";

import logger from "utils/logger";

export type ErrorProps = NextErrorProps;
export default function AdaptableErrorPage(props : ErrorProps) {
    const { statusCode: code, title, withDarkMode = false } = props;
    useEffect(() => {
        if (code === 400 || (code >= 500 && code <= 599)) {
            logger.error(`Saw code ${code}: ${title}`, undefined, new Error(title));
        }
    }, [code, title]);
    return <NextErrorPage {...props} withDarkMode={withDarkMode} />;
}

