/** @jsxImportSource @emotion/react */
import { mdiLogin, mdiLogout } from "@mdi/js";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useRouter } from "next/router";
import {
    ElementType,
    forwardRef,
    useEffect,
    useState,
} from "react";
import { useIsClient } from "usehooks-ts";
import { css } from "@emotion/react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import AdminIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import {
    blackColor,
    container,
    hexToRgb,
    mrAuto,
    title,
    whiteColor,
} from "assets/jss/material-kit-pro-react";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle";

import { useCurrentOrganizationId } from "api-support/organizations";
import Avatar from "components/Avatar";
import Button from "components/CustomButtons/Button";
import Link from "components/Link";
import LinkButton from "components/LinkButton";
import Logo, { LockupEmbedded } from "components/Logo";
import MessageDialog from "components/MessageDialog";
import { appUrls } from "utils";
import { User, useAuth } from "utils/auth";
import { useFeatureEnabled } from "utils/feature-flags";
import { useIsOnline } from "utils/online";

import HeaderInternal from "./Header";
import OrgSelector from "./OrgSelector";

export const headerHeight = 70;

const useStyles = makeStyles((theme) => ({
    ...headerLinksStyle(theme),
    sectionBlank: {
        height: "70px",
        display: "block",
    },
    container: {
        ...container,
        zIndex: 2,
        position: "relative",
        "& h1, & h4, & h6": {
            color: whiteColor,
        },
    },
    conatinerHeader2: {
        ...container,
        zIndex: 2,
        position: "relative",
        "& h1, & h4, & h6": {
            color: whiteColor,
        },
        paddingTop: "25vh",
    },
    title,
    pageHeader: {
        position: "relative",
        height: "100vh",
        maxHeight: "1600px",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center",
        "&:before": {
            background: `rgba(${hexToRgb(blackColor)}, 0.5)`,
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''",
        },
    },
    iframeContainer: {
        "& > iframe": {
            width: "100%",
            boxShadow:
        `0 16px 38px -12px rgba(${
            hexToRgb(blackColor)
        }, 0.56), 0 4px 25px 0px rgba(${
            hexToRgb(blackColor)
        }, 0.12), 0 8px 10px -5px rgba(${
            hexToRgb(blackColor)
        }, 0.2)`,
        },
    },
    mrAuto,
    textCenter: {
        textAlign: "center",
    },
    card: {
        marginTop: "60px",
    },
    formControl: {
        margin: "0",
        padding: "8px 0 0 0",
    },
    textRight: {
        textAlign: "right",
    },
    button: {
        margin: "0 !important",
    },
    profileEditLink: {
        fontSize: ".8rem",
        fontWeight: 300,
    },
    profileEmail: {
        fontSize: ".8rem",
        fontWeight: 300,
    },
    profileName: {
        fontSize: "1.2rem",
        fontWeight: 500,
        marginTop: ".5rem",
    },
    spacer: theme.mixins.toolbar,
    userMenu: {
        "& a,a:hover": {
            color: "inherit",
        },
    },
    userMenuButtonHolder: {
        display: "flex",
        justifyContent: "center",
        minWidth: "110px",
    },
    userMenuButton: {
        // Remove default button styles
        background: "none",
        color: "inherit",
        border: "none",
        padding: 0,
        outline: "inherit",
        "&:focus-visible": {
            outline: "-webkit-focus-ring-color auto 1px",
        },
    },
}));

const LogoutIcon = () => (
    <SvgIcon>
        <path d={mdiLogout} />
    </SvgIcon>
);

interface ProfileInfoProps {
    classes: Record<string, string>;
    user: User;
    component: ElementType;
    [rest: string]: any;
}

const ProfileInfo = forwardRef(({
    classes, component = ListItem, user, ...rest
}: ProfileInfoProps, ref) => {
    const [editOpen, setEditOpen] = useState(false);
    const Component = component;

    return (
        <Component ref={ref} {...rest} onClick={() => setEditOpen(true)}>
            <Box
                m={2}
                display="flex"
                alignItems="center"
                flexDirection="column"
            >
                <Avatar
                    size="50px"
                    className={classes.avatar}
                    name={user.displayName}
                    src={user.avatar}
                />
                <Typography variant="body1" className={classes.profileName}>
                    {user.displayName}
                </Typography>
                <Typography variant="body1" className={classes.profileEmail}>
                    {user.email}
                </Typography>
                <LinkButton onClick={() => setEditOpen(true)} className={classes.profileEditLink}>
                    Edit Profile Information
                </LinkButton>
                <MessageDialog
                    message="Profile information must be changed in GitHub. Sign out and sign back in to Adaptable after making changes."
                    onClose={() => setEditOpen(false)}
                    open={editOpen}
                    title="Edit Profile Information"
                />
            </Box>
        </Component>
    );
});

const UserMenu = ({
    user,
    className,
    isAdmin,
    onLogout,
}: {
    user: User;
    className?: string;
    isAdmin: boolean;
    onLogout: () => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const classes: any = useStyles();
    const orgsEnabled = useFeatureEnabled("organizations");

    return (
        <div className={className}>
            <button
                aria-label="profile menu"
                aria-controls="user-menu"
                aria-haspopup="true"
                className={classes.userMenuButton}
                type="button"
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <Avatar
                    size="30px"
                    name={user.displayName}
                    src={user.avatar}
                    sx={{ fontSize: "16px" }}
                />
            </button>
            <Menu
                className={classes.userMenu}
                id="user-menu"
                keepMounted
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
                variant="menu"
            >
                <ProfileInfo classes={classes} user={user} component={MenuItem} />
                <Divider />
                {isAdmin ? (
                    <MenuItem
                        component={Link}
                        href={appUrls.admin}
                        onClick={() => setAnchorEl(null)}
                        target="_blank"
                    >
                        <ListItemIcon><AdminIcon /></ListItemIcon>
                        <ListItemText primary="Admin" />
                    </MenuItem>
                ) : null}
                {orgsEnabled && (
                    <MenuItem component={Link} href={appUrls.organizations}>
                        <ListItemIcon><BusinessIcon /></ListItemIcon>
                        <ListItemText primary="Manage Organizations" />
                    </MenuItem>
                )}
                <MenuItem component={Link} href={appUrls.billing}>
                    <ListItemIcon><CreditCardIcon /></ListItemIcon>
                    <ListItemText primary="Billing" />
                </MenuItem>
                <MenuItem onClick={onLogout}>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </MenuItem>
            </Menu>
        </div>
    );
};

const SignInButton = ({ className }: { className?: string }) => {
    const classes: any = useStyles();
    return (
        <Button
            startIcon={<SvgIcon><path d={mdiLogin} /></SvgIcon>}
            href={`${appUrls.signin}?redirect=${appUrls.dashboard}`}
            className={classNames(classes.navLink, className)}
            color="transparent"
            LinkComponent={Link}
        >
            Sign In
        </Button>
    );
};

const InlineUserMenu = () => {
    const classes: any = useStyles();
    const auth = useAuth();
    const { isAdmin, user } = auth;
    const orgsEnabled = useFeatureEnabled("organizations");

    if (user) {
        return (
            <>
                <ProfileInfo className={classes.listItem} classes={classes} user={user} />
                {isAdmin && (
                    <ListItem className={classes.listItem}>
                        <Button
                            className={classes.navLink}
                            color="transparent"
                            href={appUrls.admin}
                            startIcon={<AdminIcon />}
                            target="_blank"
                        >
                            Admin
                        </Button>
                    </ListItem>
                )}
                {orgsEnabled && (
                    <ListItem className={classes.listItem}>
                        <Button
                            className={classes.navLink}
                            color="transparent"
                            href={appUrls.organizations}
                            startIcon={<BusinessIcon />}
                            LinkComponent={Link}
                        >
                            Manage Organizations
                        </Button>
                    </ListItem>
                )}
                <ListItem className={classes.listItem}>
                    <Button
                        startIcon={<CreditCardIcon />}
                        className={classes.navLink}
                        color="transparent"
                        href={appUrls.billing}
                        LinkComponent={Link}
                    >
                        Billing
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        startIcon={<LogoutIcon />}
                        className={classes.navLink}
                        color="transparent"
                        onClick={() => auth.logout()}
                    >
                        Sign Out
                    </Button>
                </ListItem>
            </>
        );
    }
    return <SignInButton />;
};

const MenuOrSignIn = ({ className }: { className?: string }) => {
    const isClient = useIsClient();
    const isOnline = useIsOnline();
    const auth = useAuth();
    const { isAdmin, user } = auth;
    const [state, setState] = useState(auth.state);

    useEffect(() => {
        const listener = (e: any) => setState(e.detail.state);
        auth.addEventListener("state", listener);
        return () => auth.removeEventListener("state", listener);
    }, [auth]);

    if (!isClient || (isOnline && state !== "complete")) {
        return (
            <div className={className}>
                <Skeleton width="30px" height="30px" variant="circular" />
            </div>
        );
    }
    // If we're not online, go ahead and render the sign in button
    if (!user || !isOnline) return <SignInButton className={className} />;
    return (
        <UserMenu
            className={className}
            user={user}
            isAdmin={isAdmin}
            onLogout={() => auth.logout()}
        />
    );
};

export interface HeaderProps {
    brandUrl?: string;
    className?: string;
    noOrg?: boolean;
    scrollTarget?: Node | Window;
    sticky?: boolean;
    transparent?: boolean;
}

const Header = ({
    brandUrl = "/",
    className,
    noOrg: noOrgProp = false,
    scrollTarget,
    sticky = false,
    transparent = false,
}: HeaderProps) => {
    const classes: any = useStyles();
    const { currentOrgId, setCurrentOrgId } = useCurrentOrganizationId();
    const color = transparent ? "transparent" : "primary";
    const changeColor = transparent ? {
        height: 10,
        color: "white",
    } as const : undefined;
    const isOnline = useIsOnline();
    const orgsEnabled = useFeatureEnabled("organizations");
    const { user } = useAuth();
    const router = useRouter();

    useEffect(() => {
        if (noOrgProp) return;
        if (currentOrgId == null) setCurrentOrgId(user?.personalOrgId ?? null);
        if (!orgsEnabled
            && router.pathname !== appUrls.appStatus
            && router.pathname !== appUrls.activityDetail) {
            setCurrentOrgId(user?.personalOrgId ?? null);
        }
    }, [
        currentOrgId,
        noOrgProp,
        orgsEnabled,
        router.pathname,
        setCurrentOrgId,
        user?.personalOrgId,
    ]);

    const noRenderOrg = user == null || noOrgProp || !isOnline || !orgsEnabled;
    const brand = noRenderOrg
        ? <LockupEmbedded margin="dense" style={{ marginTop: "5px", height: "45px", maxWidth: "unset" }} />
        : <Logo white style={{ marginTop: "5px", height: "45px", minWidth: "35px" }} />;
    return (
        <div
            id="HeaderWrapper"
            className={className}
            css={sticky
                ? css({
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                })
                : undefined}
        >
            <HeaderInternal
                fixed={!sticky}
                elevateOnScroll
                brand={brand}
                brandUrl={brandUrl}
                changeColorOnScroll={changeColor}
                color={color}
                scrollTarget={scrollTarget}
                leftControls={noRenderOrg ? null : (
                    <OrgSelector manageUrl={appUrls.organizations} />
                )}
                links={(
                    <div className={classes.collapse}>
                        <List className={classNames(classes.list, classes.mlAuto)}>
                            <ListItem className={classes.listItem}>
                                <Button href="/docs/what-is-adaptable" className={classes.navLink} color="transparent" LinkComponent={Link}>
                                    Product
                                </Button>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                {/* NOTE(mark): I see a 404 when using Link with this item
                                     * likely because it's a redirect.
                                     */}
                                <Button href="/docs" className={classes.navLink} color="transparent">
                                    Docs
                                </Button>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Button href="/pricing" className={classes.navLink} color="transparent" LinkComponent={Link}>
                                    Pricing
                                </Button>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Button href="/contact" className={classes.navLink} color="transparent" LinkComponent={Link}>
                                    Contact
                                </Button>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Button href={appUrls.dashboard} className={classNames(classes.navLink, classes.navLinkButton)} color="secondary" LinkComponent={Link}>
                                    App Dashboard
                                </Button>
                            </ListItem>
                            <Box sx={{ display: { xs: "none", md: "block" } }}>
                                <ListItem className={classes.listItem}>
                                    <MenuOrSignIn className={classes.userMenuButtonHolder} />
                                </ListItem>
                            </Box>
                            <Box sx={{ display: { xs: "block", md: "none" } }}>
                                <InlineUserMenu />
                            </Box>
                        </List>
                    </div>
                )}
            />
        </div>
    );
};
export default Header;
