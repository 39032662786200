import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    formatPre: {
        whiteSpace: "pre",
        fontFamily: "monospace",
        overflow: "auto",
        color: theme.palette.common.black,
    },
}));

export interface MessageDialogProps {
    buttonText?: string;
    format?: "pre";
    idBase?: string;
    message: string;
    onClose?: () => void;
    open: boolean;
    title: string;
}

export default function MessageDialog({
    buttonText = "Close",
    format,
    idBase = "message-dialog",
    message,
    onClose = () => {},
    open,
    title,
}: MessageDialogProps) {
    const { formatPre } = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${idBase}-title`}
            aria-describedby={`${idBase}-description`}
        >
            <DialogTitle id={`${idBase}-title`}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    className={format === "pre" ? formatPre : undefined}
                    id={`${idBase}-description`}
                >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                    color="primary"
                    autoFocus
                >
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
