import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

export interface PortalProps {
    children?: ReactNode;
    key?: string;
}

const Portal: FC<PortalProps> = ({ children, key }) => {
    if (!children || typeof window === "undefined") return null;
    return createPortal(children, window.document.body, key);
};
export default Portal;
