/* eslint-disable react/require-default-props */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import styles from "assets/jss/material-kit-pro-react/components/buttonStyle";

const useStyles = makeStyles(styles as any);

interface RegularButtonProps extends Omit<React.ComponentProps<typeof Button>, "color" | "size"> {
    block?: boolean;
    color?: "primary" |
    "secondary" |
    "info" |
    "success" |
    "warning" |
    "danger" |
    "rose" |
    "white" |
    "twitter" |
    "facebook" |
    "google" |
    "linkedin" |
    "pinterest" |
    "youtube" |
    "tumblr" |
    "github" |
    "behance" |
    "dribbble" |
    "reddit" |
    "instagram" |
    "transparent" |
    "gitlab";
    fileButton?: boolean;
    justIcon?: boolean;
    link?: boolean;
    round?: boolean;
    simple?: boolean;
    size?: "sm" | "lg";
    target?: "_blank" | string;
}

const RegularButton = React.forwardRef(({
    block,
    children,
    className,
    color = "primary",
    disabled,
    fileButton,
    fullWidth,
    justIcon,
    link,
    round,
    simple,
    size = "sm",
    ...rest
}: RegularButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const classes = useStyles();
    const btnClasses = classNames(className, {
        [classes.button]: true,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [classes.fileButton]: fileButton,
    });
    return (
        <Button {...rest} ref={ref} className={btnClasses}>
            {children}
        </Button>
    );
});

RegularButton.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "twitter",
        "facebook",
        "google",
        "linkedin",
        "pinterest",
        "youtube",
        "tumblr",
        "github",
        "behance",
        "dribbble",
        "reddit",
        "instagram",
        "transparent",
        "gitlab",
    ]),
    size: PropTypes.oneOf(["sm", "lg"]),
    simple: PropTypes.bool,
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool,
    fileButton: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default RegularButton;
