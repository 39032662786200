import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import classNames from "classnames";

import Button from "components/CustomButtons/Button";
import Logo from "components/Logo";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles";
import {
    container,
    title,
    description,
    section,
    btnLink,
    twitterColor,
    dribbbleColor,
    instagramColor,
    grayColor,
} from "assets/jss/material-kit-pro-react";
import FooterInternal from "./Footer";

const useStyles = makeStyles((theme) => ({
    ...imagesStyles,
    container,
    title,
    description,
    section: {
        ...section,
        padding: "70px 0px",
    },
    socialFeed: {
        "& p": {
            display: "table-cell",
            verticalAlign: "top",
            overflow: "hidden",
            paddingBottom: "10px",
            maxWidth: 300,
        },
        "& i": {
            fontSize: "20px",
            display: "table-cell",
            paddingRight: "10px",
        },
    },
    img: {
        width: "20%",
        marginRight: "5%",
        marginBottom: "5%",
        float: "left",
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0",
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto",
    },
    left: {
        [theme.breakpoints.up("sm")]: {
            float: "left!important" as "left",
        },
        display: "block",
    },
    right: {
        padding: "15px 0",
        margin: "0",
        [theme.breakpoints.up("sm")]: {
            float: "right",
        },
    },
    aClass: {
        textDecoration: "none",
        backgroundColor: "transparent",
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: 500,
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block",
    },
    footerBrand: {
        padding: "15px 15px",
        lineHeight: "50px",
        marginLeft: "-15px",
        textDecoration: "none",
        "& img": {
            height: "1.7rem",
            transform: "translateY(2px)",
        },
    },
    pullCenter: {
        display: "inline-block",
        float: "none",
    },
    rightLinks: {
        [theme.breakpoints.up("sm")]: {
            float: "right!important" as "right",
        },
        "& ul": {
            marginBottom: 0,
            padding: 0,
            listStyle: "none",
            "& li": {
                display: "inline-block",
            },
            "& a": {
                display: "block",
            },
        },
        "& i": {
            fontSize: "20px",
        },
    },
    linksVertical: {
        "& li": {
            display: "block !important",
            marginLeft: "-5px",
            marginRight: "-5px",
            "& a": {
                padding: "5px !important",
            },
        },
    },
    footer: {
        "& ul li": {
            display: "inline-block",
        },
        "& h4, & h5": {
            color: grayColor[1],
            textDecoration: "none",
        },
        "& ul:not($socialButtons) li a": {
            color: "inherit",
            padding: "0.9375rem",
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "uppercase",
            borderRadius: "3px",
            textDecoration: "none",
            position: "relative",
            display: "block",
        },
        "& small": {
            fontSize: "75%",
            color: grayColor[10],
        },
        "& $pullCenter": {
            display: "inline-block",
            float: "none",
        },
    },
    iconSocial: {
        width: "41px",
        height: "41px",
        fontSize: "24px",
        minWidth: "41px",
        padding: 0,
        overflow: "hidden",
        position: "relative",
    },
    copyright: {
        color: grayColor[0],
        fontSize: "13px",
        paddingTop: "15px",
        paddingBottom: "0px",
    },
    socialButtons: {
        "& li": {
            display: "inline-block",
        },
    },
    btnTwitter: {
        ...btnLink,
        color: twitterColor,
    },
    btnDribbble: {
        ...btnLink,
        color: dribbbleColor,
    },
    btnInstagram: {
        ...btnLink,
        color: instagramColor,
    },
    icon: {
        top: "3px",
        width: "18px",
        height: "18px",
        position: "relative",
    },
    customFormControl: {
        paddingTop: "14px",
    },
    contentRow: {
        display: "flex",
        flexFlow: "row wrap",
    },
    clearFix: {
        clear: "both",
    },
}));

const Footer = ({ className }: { className?: string }) => {
    const classes = useStyles();

    return (
        <FooterInternal
            theme="white"
            className={className}
            content={(
                <div>
                    <div>
                        <div className={classes.left}>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <a href="/" className={classes.footerBrand}>
                                <Logo lockup />
                            </a>
                        </div>
                        <div className={classes.left}>
                            <List className={classes.list}>
                                <ListItem className={classes.inlineBlock}>
                                    <a href="/privacy" className={classes.block}>
                                        Privacy
                                    </a>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <a href="/terms" className={classes.block}>
                                        Terms
                                    </a>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <a href="https://adaptable.statuspage.io/" className={classes.block}>
                                        Status
                                    </a>
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.rightLinks}>
                            <ul>
                                <li>
                                    <Button
                                        href="https://twitter.com/adaptableio"
                                        target="_blank"
                                        justIcon
                                        simple
                                    >
                                        <i className="fab fa-twitter" />
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        href="https://github.com/adaptable"
                                        target="_blank"
                                        justIcon
                                        simple
                                    >
                                        <i className="fab fa-github" />
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={classNames(classes.copyright, classes.clearFix)}>
                        Copyright &copy;2020-
                        {(new Date()).getFullYear()}
                        {" "}
                        Adaptable.io
                    </div>
                </div>
            )}
        />
    );
};
export default Footer;
