/* eslint-disable react/destructuring-assignment */
import React from "react";
import MuiAvatar, { AvatarProps as MuiAvatarProps } from "@mui/material/Avatar";

export interface AvatarProps extends MuiAvatarProps {
    name?: string | undefined;
    size?: string | undefined;
}

const defaultColors = [
    "#d73d32",
    "#7e3794",
    "#4285f4",
    "#67ae3f",
    "#d61a7f",
    "#ff4080",
    "#16DB93",
];

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return defaultColors[hash % defaultColors.length];
}


function StringAvatar({
    name, size, src, ...props
}: AvatarProps) {
    const sx: any = props.sx || {};

    if (name) {
        const initials = name.toUpperCase().split(/\s+/g).slice(0, 2).map((w) => w.slice(0, 1));
        props.children = initials.join("");
        sx.backgroundColor = stringToColor(name);
    }
    if (size) {
        sx.height = size;
        sx.width = size;
    }

    return (
        <MuiAvatar
            {...props}
            sx={sx}
            title={name}
        />
    );
}

function ImageAvatar({
    children, name, size, src, ...props
}: AvatarProps) {
    if (!src) return children;
    const sx: any = props.sx || {};
    if (size) {
        sx.height = size;
        sx.width = size;
    }

    return (
        <MuiAvatar
            {...props}
            alt={name}
            title={name}
            src={src}
            sx={sx}
        >
            {children}
        </MuiAvatar>
    );
}

/**
 * User avatar component
 *
 * This is a light wrapper around the MUI Avatar component that adds:
 * - Parsing of name into initials for fallback text
 * - Varying avatar colors for fallback text
 * It relies on the MUI Avatar behavior to fall back to rendering
 * its children if the `src` image fails to load.
 * The reason that nested MUI Avatars are needed is because if the `src` image
 * is set and loads, the background color needs to be transparent to handle
 * images with a transparent background.
 */
export default function Avatar(props: AvatarProps) {
    return (
        <ImageAvatar {...props}>
            <StringAvatar {...props} />
        </ImageAvatar>
    );
}
